<template>
    <settings-wrapper>
        <template v-slot:child>
            <main class="paystack-main">
                <header class="paystack-logo-header">
                    <img src="@/assets/images/paystack.svg" alt="paystack-logo" width="300" />
                </header>
                <section class="paystack-section1">
                    <p class="paystack-section1-p">Add your <span>Paystack Live Secret Key</span></p>
                    <input type="text" class="pay-width paystack-section1-input" /> <br />
                    <button class="pay-width paystack-section1-button">Complete Setup</button>
                </section>
                <section class="paystack-section2">
                    <p class="paystack-section2-p">
                        Login to your <a href="#">Paystack dashboard</a> and copy your live secret key. Paste it in the field
                        above.
                        <span>
                            Your API key is used to sync your customer data and subscriptions to Engage.
                            <a href="#">Learn more</a></span
                        >
                    </p>
                </section>
                <section class="paystack-section3">
                    <p class="paystack-back"><router-link :to="{ name: 'settingIntegration' }" href="#">Back</router-link></p>
                </section>
            </main>
        </template>
    </settings-wrapper>
</template>

<script>
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
export default {
    components: { SettingsWrapper }
}
</script>

<style>
.paystack-main {
    margin: 7% auto;
    width: 70%;
    box-sizing: border-box !important;
}
.paystack-logo-header {
    margin-bottom: 8%;
}
.paystack-section1-p {
    margin: 0;
}
.paystack-section1-p span {
    /* background-color: blue; */
    padding: 2px 0;
}
.paystack-section1-input {
    margin: 8px 0;
}
.pay-width {
    width: 80%;
    box-sizing: border-box;
    height: 35px;
}
.paystack-section1-button {
    color: white;
    background-color: #132c8c;
    border: none;
    border-radius: 2px;
    margin-bottom: 2%;
}
.paystack-section2 {
    width: 80%;
}
.paystack-section2-p {
    line-height: 1.5em;
}
.paystack-section2-p a {
    text-decoration: none;
    color: #132c8c;
}
.paystack-section2-p span {
    background-color: yellow;
}
.paystack-section3 {
    width: 80%;
}
.paystack-back {
    margin-top: 50px;
    text-align: center;
}
.paystack-back a {
    text-decoration: none;
    color: #132c8c;
}
@media only screen and (max-width: 800px) {
    .paystack-logo-header {
        margin-bottom: 15%;
    }
}
</style>
